'use client';

import { Variant, motion } from 'framer-motion';
const classes = {
  wrap: 'relative text-primary h-2 overflow-hidden w-full bg-[#4c77ee]',
  barWrap: 'flex justify-start',
  bar: 'h-2 bg-[#9c4caf]'
};
const barStyles: Record<string, Variant> = {
  indeterminate: {
    width: ['0%', '100%'],
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 2.5
    }
  }
};
export const LoadingStripe = () => {
  return <div className={classes.wrap}>
      <div className={classes.barWrap}>
        <motion.div className={classes.bar} animate="indeterminate" variants={barStyles} />
      </div>
    </div>;
};